<template>
  <div class="score">
    <tojo-error-page v-if="!isScore" :blankData="this.blankData" />
    <div v-if="isScore" class="score_content">
      <div v-for="(item, index) in scoredatalist.list" :key="index">
        <div class="years">{{ item.k }}年</div>
        <div class="content" v-for="(itm, indx) in item.v" :key="indx">
          <div class="month">{{ itm.month }}月</div>
          <div class="rank" :style="rankMapStyle[itm.pfLevel]">{{ itm.pfLevel }}</div>
        </div>
      </div>
    </div>
    <!-- <div v-if="isScore" class="footer el-pagination is-background"> -->
    <el-pagination
      v-if="scoredatalist.total"
      class="pagination"
      background
      :page-size="pagesize"
      :current-page="currentPage"
      @current-change="currentChange"
      layout="prev, pager, next"
      :total="scoredatalist.total"
    >
    </el-pagination>
  </div>
  <!-- </div> -->
</template>

<script>
import tojoErrorPage from '@/components/business/blank/blank'
export default {
  name: 'score-home-index',
  props: {
    // 展示的数组
    scoredatalist: {
      type: Object,
      default: () => {}
    },
    // 时候还有数据
    isScore: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      blankData: {
        type: 'notdata'
      },
      rankMapStyle: {
        S: { color: '#c89557' },
        A: { color: '#3d7eff' },
        B: { color: '#ccc' },
        C: { color: '#ff526a' },
        D: { color: '#ff526a' }
      },
      pagesize: 10,
      currentPage: 1,
      loading: false,
      height: 300
    }
  },
  components: {
    tojoErrorPage
  },
  methods: {
    load() {
      this.loading = true
    },
    currentChange(val) {
      this.currentPage = val
      let obj = {
        size: this.pagesize,
        page: this.currentPage
      }
      this.$emit('onloadchange', obj)
    }
  }
}
</script>

<style lang="scss" scoped>
.score {
  height: 100%;
  .el-pagination {
    z-index: 2;
    box-shadow: 0px -2px 20px 0px rgba(0, 0, 0, 0.05);
    background: #ffffff;
    text-align: center;
    padding: 15px 0;
  }
  .rank {
    padding-right: 5px;
    padding-left: 2px;
  }
  .score_content {
    overflow: auto;
    width: 100%;
    height: calc(100% - 58px);
  }
  .years {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    padding-top: 20px;
    padding-left: 15px;
  }
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 1px 0px 0px #eeeeee;
    div {
      padding: 23px 25px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }
    .month {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }
  .footer {
    height: 43px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
