<template>
  <tojoy-pagination-list
    schema="block"
    :total="(targetData && targetData.total) || 0"
    :data="(targetData && targetData.list) || []"
    class="infodata-target-view"
    @page-change="handleChangePage"
  >
    <template #item="{data}">
      <tojoy-card
        :data="{
          time: data.month < 10 ? `0${data.month}` : data.month,
          unit: data.year
        }"
        :class="{ 'org-card': data.isOrg }"
      >
        <template #rightcard>
          <template v-if="data.evalType === 2">
            <tojoy-card
              v-for="(item, index) in data.detailOutputs"
              :key="index"
              schema="people"
              class="eval-type"
            >
              <template #upcard>
                <section class="weight-wrap">
                  <h3 class="weight-title">
                    {{ `考评${data.evalType}：权重${item.evalWeight}%` }}
                  </h3>
                  <span :class="`weight-state color${item.state}`" v-if="item.state !== 5">{{ weightState[item.state] }}</span>
                </section>
              </template>
              <template #downcard>
                <target-row v-for="(row, index) in item.indexOutputs" :data="row" :key="index" />
              </template>
            </tojoy-card>
          </template>
          <template v-if="data.evalType === 1">
            <tojoy-card
              v-for="(item, index) in data.detailOutputs"
              :key="index"
              schema="people"
              class="eval-type--not"
            >
            <template #upcard>
                <section class="weight-wrap">
                  <h3 class="weight-title">
                    <!-- {{ `考评${data.evalType}：权重${item.evalWeight}%` }} -->
                  </h3>
                  <div class="weight-nullval" v-if="item.indexOutputs.length < 1">您还未填写目标，请尽快填写！</div>
                  <span :class="`weight-state color${item.state}`" v-if="item.state !== 5">{{ weightState[item.state] }}</span>
                </section>
              </template>
              <template #downcard>
                <target-row class="first-evalType" v-for="(row, index) in item.indexOutputs" :data="row" :key="index" />
              </template>
            </tojoy-card>
          </template>
        </template>
      </tojoy-card>
    </template>
  </tojoy-pagination-list>
</template>

<script>
import TojoyCard from '@/components/business/card'
import TojoyPaginationList from '@/components/business/pagination-list'
import TargetRow from './target-row'

export default {
  components: {
    TojoyCard,
    TojoyPaginationList,
    TargetRow
  },
  props: {
    targetData: {
      type: Object,
      default: () => {
        return {
          list: [],
          total: 0
        }
      }
    }
  },
  data() {
    return {
      weightState: {
        1: '待提交',
        2: '待确认',
        3: '待自评',
        4: '待考评',
        5: '已完成',
        6: '已驳回'
      }
    }
  },
  created() {
    console.log(this.targetData)
  },
  methods: {
    handleChangePage(val) {
      this.$emit('page-change', val)
    }
  }
}
</script>

<style lang="scss">
.infodata-target-view {
  .org-card {
    .tojoy-card--left {
      position: relative;
      &::before {
        content: '';
        width: 26px;
        height: 26px;
        position: absolute;
        top: -10px;
        left: -10px;
        display: block;
        background: url('../../assets/images/org-tip.png') no-repeat top;
        background-size: 100%;
      }
    }
  }
  .eval-type {
    .tojoy-card--up {
      margin-top: 10px;
    }
  }
  .weight {
    &-wrap {
      display: flex;
      justify-content: space-between;
    }
    &-title {
      font-size: 16px;
      font-weight: 500;
      color: $lightblack;
      line-height: 16px;
    }
    &-nullval{
      padding-top: 8px;
    }
    &-state {
      display: inline-block;
      padding: 7px 10px;
      border-radius: 3px;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
    }
  }
    .color1{
      background: $fef2e8;
      color: $yellow;
    }
    .color2{
      background: $ebf2ff;
      color: $blue;
    }
    .color3{
      background: $fef2e8;
      color: $yellow;
    }
    .color4{
      background: $ebf2ff;
      color: $blue;
    }
    .color5{
      background: $f7f7f7;
      color: #9999;
    }
    .color6{
      background: $fef2e8;
      color: $yellow;
    }
  .target-row {
    display: flex;
    padding: 18px 0;
    border-bottom: 1px solid $linecolor;
    &__title {
      flex: 1;
      font-size: 15px;
      font-weight: 400;
      color: $lightblack;
      line-height: 15px;
    }
    &__time {
      font-size: 12px;
      font-weight: 400;
      color: $darkgrey;
      // line-height: 12px;
    }
    .el-progress {
      width: 140px;
      flex-direction: row-reverse;
      display: flex;
      align-items: center;
      &-bar {
        padding-right: 0;
        margin-right: 0;
      }
      &__text {
        font-size: 12px;
        font-weight: 400;
        color: $blue;
        line-height: 12px;
        margin-right: 10px;
      }
    }
  }

  .tojoy-card--right {
    margin-left: 15px;
    .eval-type {
      &:last-of-type {
        .tojoy-card--down {
          .target-row {
            &:last-of-type {
              border-bottom: none;
            }
          }
        }
      }
    }
    .eval-type--not {
      .tojoy-card--down {
        .target-row {
          &:last-of-type {
            border-bottom: none;
          }
          .target-row__time{
              // padding-top: 4px;
            }
        }
      }
    }
  }
  & > .tojoy-pagination-list__wrap {
    margin: 0;
    padding: 0;
  }
  &.tojoy-pagination-list {
    &__notdata {
      margin: 0;
    }
  }
}
</style>
