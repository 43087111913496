<template>
  <div class="content">
    <header>
      <div class="header_top">
        <div class="header_acunt">
          <div class="avatar" v-if="infodata.avatar"><img :src="infodata.avatar" /></div>
          <div class="avatar avatardefault" v-else-if="infodata.userName">{{ infodata.userName.slice(-2) }}</div>
          <div class="userinfo">
            <div class="userinfo-name">
              <div class="name">{{ infodata.userName }}</div>
              <div class="medalGrade">
                <div v-for="(item, index) in infodata.medalGrade" :key="index" class="icon">
                  <img :src="item.icon" />
                  <span :style="{ color: item.color }">{{ item.medalNum | filterMedalNum }}</span>
                </div>
              </div>
            </div>
            <div class="userinfo-joblist" v-for="(item, index) in infodata.jobList" :key="index">
              <p>{{ item.jobName }}</p>
              <p>{{ item.orgChain }}</p>
            </div>
          </div>
        </div>
        <div class="header_list">
          <div>粉丝 {{ infodata.fansNumber }}</div>
          <div class="attention" v-if="infodata.isShowButton === 1 && myselfUserId !== followPeopleUserid" @click="setAttention(infodata.isAttention)">
            <i :class="infodata.isAttention === 0 ? 'el-icon-plus' : 'el-icon-check'"></i>
            {{ infodata.isAttention === 0 ? '关注' : '已关注' }}
          </div>
        </div>
      </div>
      <div class="header_bottom">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item v-for="item in menuList" :key="item.activeIndex" :index="item.activeIndex">{{ item.val }}</el-menu-item>
        </el-menu>
      </div>
    </header>
    <div class="banner">
      <!-- 绩效 -->
      <score-item-card @onloadchange="getnewlist" :isScore="isScore" :scoredatalist="scoredatalist" class="scoreitemcard" v-if="activeIndex == '1'" />
      <!-- <three-clear @getdatalist="listreportinfo"  v-if="activeIndex == '2'" /> -->
      <!-- 三清列表 -->
      <Black :blankData="blankData" v-if="nopermission"></Black>
      <tojoy-pagination-list :currentPage="page" @page-change="pageChange" :total="total" :data="completeData" v-show="activeIndex == '2' && !nopermission" >
        <template #item="{ data }">
          <three-item
            :key="data.id"
            :data="data"
            :form-config="formConfig"
            @click-praise="handleClickPraise"
            @argue-discuss="handleArgueDiscuss"
            @submit-discuss="handleSubmitDiscuss"
            @shift-comment="handleShiftComment"
          />
        </template>
      </tojoy-pagination-list>
      <tojo-Aims v-if="activeIndex == '3' && !nopermission" :target-data="targetData" @page-change="handleChangePage" />
      <tojo-Material :infodataList="infodata" v-if="activeIndex == '4' && !nopermission" />
    </div>
  </div>
</template>
<script>
import Black from '@/components/business/blank/blank';
import TojoyPaginationList from '@/components/business/pagination-list'
import ThreeItem from '@/components/thrclear/three-item'
import { uIdyear, listreportinfo, focuslist, homepage } from '@/api/employeedata.js'
import { cancel, attentionsave, praise, deleteComment, comment } from '@/api/thrclear.js'
import { mapState } from 'vuex'
import scoreItemCard from '@/components/scre-home-page/index.vue'
import tojoAims from '@/components/infodata/tojoaims.vue'
import tojoMaterial from '@/components/infodata/tojomaterial.vue'
import { Message, MessageBox } from 'element-ui'
import { medal } from '@/config/config.js'
export default {
  name: 'homepage-navigation',
  data() {
    return {
      nopermission: false,
      blankData: {
        type: 'nopermission'
      },
      completeData: [],
      formConfig: {},
      page: 1,
      size: 10,
      total: 0,
      queryyear: 0,
      scoredatalist: {},
      isScore: true,
      followPeopleUserid: '',
      infodata: {
        userName: '',
        jobName: '',
        orgChain: [],
        fansNumber: ''
      },
      activeIndex: '1', // TODO
      medalGrade: [],

      menuList: [
        {
          val: '绩效',
          activeIndex: '1'
        },
        {
          val: '三清',
          activeIndex: '2'
        },
        {
          val: '目标',
          activeIndex: '3'
        },
        {
          val: '资料',
          activeIndex: '4'
        }
      ],
      pagination: {
        page: 1,
        size: 10
      },
      targetData: {}
    }
  },
  computed: {
    ...mapState({
      userName: state => state.userInfo.userName,
      myselfUserId: state => state.userInfo.userId
    })
  },
  created() {
    this.followPeopleUserid = this.$route.query.userId
    this.homepage({ dataUserId: this.followPeopleUserid })
    this.firstgetdate()
    this.uIdyear()
    this.handleSelect(this.activeIndex)
  },
  components: {
    scoreItemCard,
    TojoyPaginationList,
    tojoAims,
    tojoMaterial,
    ThreeItem,
    Black
  },
  methods: {
    // 三清页面-删除评论
    async handleShiftComment({ id, content, reportId, userName }) {
      MessageBox.confirm(`确定删除这条评论吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteComment({ content, reportId, userName, id }).then(res => {
          if (res.code === '000000') {
            // TODO 删除评论-处理成局部刷新
            this.completeData.map(c => {
              if (Number(c.id) === Number(reportId)) {
                c.commentOutputList = c.commentOutputList.filter(cc => cc.id !== id)
              }
              return c
            })
            Message.success(res.msg)
          } else {
            Message.error(res.msg)
          }
        })
      })
    },
    // 三清页面-点赞
    async handleClickPraise({ id, isPraise }, operation) {
      const { code, data } = await praise({ reportId: id, userName: this.userName })
      if (code === '000000') {
        // TODO 点赞处理成局域更新
        this.completeData.map(c => {
          if (c.id === id) {
            c.isPraise = operation
          }
          return c
        })
        this.listreportinfo()
      }
    },
        // 三清页面-点击评论
    handleArgueDiscuss(item) {
      this.completeData = this.completeData.map(ite => {
        ite.isArgue = item.id === ite.id ? !ite.isArgue : ite.isArgue
        return ite
      })
    },
    // 三清页面-点击分页
    pageChange(val) {
      this.page = val
      this.listreportinfo()
    },
    // 三清页面-点击发布按钮
    handleSubmitDiscuss(val) {
      if (val.comment) {
        let obj = {
          content: val.comment,
          reportId: val.id,
          userName: this.userName
        }
        comment(obj).then(res => {
          if (res && res.code === '000000') {
            this.listreportinfo()
            this.completeData = this.completeData.map(ite => {
              ite.isArgue = val.id === ite.id ? !ite.isArgue : ite.isArgue
              return ite
            })
          }
        })
      }
    },
    setAttention(val) {
      if (val === 0) {
        this.attentionsave(this.followPeopleUserid)
      } else {
        this.cancel(this.followPeopleUserid)
      }
    },
    // 取消关注
    cancel(val) {
      cancel({ attentionId: val }).then(res => {
        if (res && res.code === '000000') {
          Message({
            message: '取消关注成功！',
            type: 'success'
          })
          this.homepage({ dataUserId: this.followPeopleUserid })
        } else {
          Message({
            message: '取消关注失败！',
            type: 'error'
          })
        }
      })
    },
    // 关注
    attentionsave(val) {
      attentionsave({ attentionId: val }).then(res => {
        if (res && res.code === '000000') {
          Message({
            message: '关注成功！',
            type: 'success'
          })
          this.homepage({ dataUserId: this.followPeopleUserid })
        } else {
          Message({
            message: '关注失败！',
            type: 'error'
          })
        }
      })
    },
    // 查询个人资料
    handleSelect(key, keyPath) {
      this.nopermission = false;
      this.activeIndex = key;
      this.page = 1;
      switch (key) {
        case '1':
          // 绩效查询接口
          this.uIdyear()
          break
        case '2':
          // 三清查询接口
          this.listreportinfo()
          break
        case '3':
          // 目标查询接口
          this.focuslist({ page: this.pagination.page, size: this.pagination.size, pointId: this.followPeopleUserid })
          break
        case '4':
          // 目标查询接口
          this.homepage({ dataUserId: this.followPeopleUserid })
          break
      }
    },
    // 获取当前年，当前月
    getnewlist(val) {
      this.page = val.page
      this.size = val.size
      this.uIdyear()
    },
    firstgetdate() {
      let date = new Date()
      let years = date.getFullYear()
      this.queryyear = years
    },
    // 绩效查询接口
    uIdyear() {
      let data = {
        page: this.page,
        size: this.size,
        uId: this.followPeopleUserid
        // uId: '1398823624638561'
      }
      uIdyear(data).then(res => {
        if (res && res.code === '000000') {
          if(res.data.list.length > 0) {
            this.scoredatalist = res.data
          } else {
            this.isScore = false;
            this.total = 0;
          }
        }
      })
    },
    // 三清查询接口
    listreportinfo() {
      let data = {
        page: this.page,
        size: this.size,
        userSeeId: this.followPeopleUserid
        // userSeeId: '1398823624638561'
      }
      listreportinfo(data).then(res => {
        if (res && res.code === '000000') {
          this.total = res.data.total
          this.completeData = []
          this.formConfig = res.data.formConfigMap
          if (res.data.list) {
            this.completeData = res.data.list.map(item => {
              item.isArgue = false
              item.comment = ''
              return item
            })
          }
        } else {
          this.nopermission = true;
          this.total = 0;
        }
      })
    },
    /**
     * 目标查询接口
     */
    async focuslist(params) {
      const { code, data } = await focuslist(params)
      if (code === '000000') {
        if(data) {
          this.targetData = data;
          this.nopermission = false;
        } else {
          this.blankData.type = 'nopermission';
          this.nopermission = true;
        }
      }
    },

    /**
     * 目标查询接口-分页
     */
    handleChangePage(page) {
      this.focuslist({ page: page, size: this.pagination.size, pointId: this.followPeopleUserid })
    },

    // 个人资料查询
    homepage(data) {
      homepage(data).then(res => {
        if (res && res.code === '000000') {
          this.infodata = res.data
          let medalGrade = res.data.medalGrade
          if (medalGrade && medalGrade.length > 0) {
            medal.forEach(element => {
              medalGrade.forEach(e => {
                if (element.medalType === e.medalType) {
                  e.icon = element.icon
                  e.color = element.color
                }
              })
            })
          }
          this.medalGrade = medalGrade
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  height: 100%;
  padding: 19px 30px 0;
  /deep/.tojoy-pagination-list__wrap{
    margin: 0;
    padding: 0;
  }
  /deep/.small-user-card__right{
    display: none;
  }
  /deep/.tojoy-pagination-list__notdata{
    margin: 0;
  }
  /deep/.errorPage{
    margin: 0;
  }
  header {
    width: 100%;
    background: #3d7eff;
    padding: 30px 30px 0;
    .header_top {
      border-bottom: 1px solid rgba($color: #ffffff, $alpha: 0.2);
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
      .header_acunt {
        display: flex;
        justify-content: center;
        .avatar {
          width: 86px;
          height: 86px;
          border: 4px solid #fff;
          border-radius: 50%;
          background: #ffffff;
          flex-shrink: 0;
          text-align: center;
          img {
            display: block;
            width: 78px;
            height: 78px;
            border-radius: 50%;
          }
        }
        .avatardefault {
          background: $blue;
          line-height: 78px;
          color: #fff;
          font-size: 30px;
        }
        .userinfo {
          margin-left: 22px;
          .userinfo-name {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            .name {
              font-size: 22px;
              color: #fff;
              margin-right: 10px;
            }
            .medalGrade {
              .icon {
                width: 16px;
              }
              span {
                font-size: 12px;
              }
            }
          }
          .userinfo-joblist {
            margin-bottom: 12px;
            p {
              font-size: 12px;
              line-height: 16px;
              color: rgba($color: #ffffff, $alpha: 0.8);
              margin-bottom: 4px;
            }
          }
        }
      }
      .header_list {
        display: flex;
        flex-shrink: 0;
        margin: 28px 0 0 50px;
        div {
          padding: 0 14px;
          height: 32px;
          line-height: 32px;
          background: rgba(0, 0, 0, 0.2);
          border-radius: 0px 3px 3px 0px;
          font-size: 14px;
          color: #ffffff;
          margin-right: 1px;
        }
        div:nth-child(1) {
          border-radius: 3px 0px 0px 3px;
        }
        .attention {
          cursor: pointer;
        }
      }
    }
    .header_bottom {
      height: 69px;
      .el-menu-item {
        font-size: 16px !important;
      }
      .el-menu {
        background: rgba($color: #000000, $alpha: 0);
        color: rgba($color: #ffffff, $alpha: 1) !important;
        :hover {
          background: rgba($color: #000000, $alpha: 0);
          color: rgba($color: #ffffff, $alpha: 1);
        }
      }
      .el-menu-item {
        background: rgba($color: #000000, $alpha: 0);
        color: rgba($color: #ffffff, $alpha: 0.6);
      }
      .el-menu.el-menu--horizontal {
        border-bottom: none;
      }
      .el-menu--horizontal > .el-menu-item.is-active {
        border-bottom: 2px solid #ffffff !important;
        color: rgba($color: #ffffff, $alpha: 1);
        background: rgba($color: #000000, $alpha: 0);
      }
    }
  }
  .banner {
    width: 100%;
    height: calc(100% - 218px);
    overflow: auto;
    .scoreitemcard {
      background: white;
    }
  }
}
</style>
