<template>
    <div class="conter">
        <div class="contentlist nameFirst">
            <div class="title">姓名</div>
            <div class="value">{{infodataList.userName}}</div>
        </div>
        <div class="contentlist">
            <div class="title">性别</div>
            <div class="value" v-html="infodataList.sex == 1 ? '男' : '女'"></div>
        </div>
        <div class="contentlist">
            <div class="title">部门</div>
            <div class="value">{{infodataList.mainOrgName}}</div>
        </div>
        <div class="contentlist">
            <div class="title">员工编号</div>
            <div class="value">{{infodataList.workNo}}</div>
        </div>
        <div class="contentlist marginTop">
            <div class="title">邮箱</div>
            <div class="value">{{infodataList.email}}</div>
        </div>
        <div class="contentlist">
            <div class="title">手机号</div>
            <div class="value">{{infodataList.mobile}}</div>
        </div>
    </div>
</template>
<script>
export default {
  props: {
    infodataList: {
      type: Object,
      default: () => {}
    }
  },
}
</script>
<style lang="scss" scoped>
    .conter{
        height: 100%;
        background: white;
        .contentlist{
            height: 36px;
            display: flex;
            align-items: center;
            .title{
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                padding-left: 15px;
                width: 100px;
            }
            .value{
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
            }
        }
        .nameFirst{
            margin-top: 15px;
            display: inline-block;
            width: 100%;
            .value{
                float: left;
                line-height: 36px;
            }
            .title{
                float: left;
                line-height: 36px;
            }
        }
        .marginTop{
            margin-top: 24px;
        }
    }
</style>