const targetRow = {
  name: 'target-row',

  functional: true,

  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },

  render: (h, { props }) => {
    const { data } = props
    let _progress = data.progress ?? 0;
    return (
      <div class='target-row'>
        <p class='target-row__title'>{data.name}</p>
        <span class='target-row__time'>
          {[1, 2].includes(data.type) ? `截止：${data?.planFinishDate ?? ''}` : `${data.frequency ?? ''}例行`}
        </span>
        <el-progress color='#3D7EFF' format={ ()=> {return `${_progress}%`}} percentage={_progress  > 100 ? 100 : _progress}></el-progress>
      </div>
    )
  }
}

export default targetRow
